// Variables
//----------

// Colors
// --------------------------------------------------------

$palette: (
  white: #FFFFFF,
  neutral-10: #F7F7F7,
  neutral-15: #F4F4F4,
  neutral-20: #ECECEC,
  neutral-25: #EEEEEE,
  neutral-30: #DDDDDD,
  neutral-35: #C3C3C3,
  neutral-37: #C4C4C4,
  neutral-40: #9A9A9A,
  neutral-45: #828282,
  neutral-50: #696969,
  neutral-55: #777777,
  neutral-60: #596069,
  neutral-70: #555555,
  neutral-75: #3D434B,
  neutral-80: #333333,
  neutral-90: #222222,
  neutral-95: #1F1F1F,
  alert-green: #3EAF6A,
  alert-gold: #F0AD4E,
  alert-red: #CC0000,
  data-green: #07E065,
  data-blue: #00DCFF,
  data-fuschia: #E05EFF,
  data-orange: #FFA229,
  data-periwinkle: #6E99FF,
  data-coral: #FF6161,
  info-blue: #A0C3EC,
  info-light-blue: #D7E6FA,
  info-light-gray: #F0F6FF,
  primary-bright-1: #00BEFF,
  primary-bright-2: #69AFFF,
  primary-dark-1: #1D3659,
  primary-dark-2: #1A304F,
  primary-dark-3: #172B47,
  primary-mid-1: #4B8EDB,
  primary-mid-2: #3769AC,
  primary-mid-3: #2E5790,
  secondary-bright: #F58A38,
  secondary-ultra-bright: #D54E21,
  secondary-dark: #274979,
  secondary-mid: #3D73BE,
  siemens-teal: #009999,
  sp50-gray: #DEECF9,
  sp50-mint: #CBF7E6,
  sp50-rose: #F5E4F4
);

// Responsive breakpoints
// --------------------------------------------------------
$breakpoints: (
  130: 1280px,
  110: 1120px,
  100: 1022px,
  75: 750px,
  70: 700px,
  65: 650px,
  60: 620px,
  50: 500px,
  45: 475px,
  40: 440px
);

// Measurements
// --------------------------------------------------------
$container-widths: (
  max: 1280px,
  wide: 1000px,
  primary: 920px,
  narrow: 440px
);

$padding-sizes: (
  x-x-large: 80px,
  x-large: 50px,
  large: 40px,
  medium-large: 30px,
  medium: 25px,
  small: 20px,
  x-small: 15px,
  x-x-small: 10px
);

$duration: 0.2s;
$timing: ease;

// Typography
// --------------------------------------------------------
$line-height-base: 1.36;

$font-stack-base: "Avenir Next","Helvetica Neue","Segoe UI","Oxygen","Ubuntu","Cantarell","Open Sans",sans-serif;

$regular: 400;
$medium: 500;
$demi-bold: 600;
$bold: 700;

// Appearance
// --------------------------------------------------------

// Cross-component variables
// --------------------------------------------------------
$card-border-radius: 6px;
$filter-border-radius: 25px;

$shadow: (
  card: 0px 25px 15px -17px rgba(#000, 0.07),
  filter: 0 12px 16px rgba(#000, 0.41)
);

$card-size-breakpoints: (
  wide: 1300px,
  narrow: 860px
);

$site-header: (
  logo-width-large: 165px,
  search-container-width: 707px,
  vertical-padding-large: map-get($padding-sizes, medium-large),
  vertical-padding-small: map-get($padding-sizes, x-small),
  lateral-padding-large: map-get($padding-sizes, x-large),
  lateral-padding-small: map-get($padding-sizes, x-small)
);

$navigation: (
  icon-size: 32px,
  heading-padding: 14px,
  vertical-padding: 7px,
  left-padding: map-get($padding-sizes, large),
  gutter: 12px
);

$z-index: (
  drawer: 75,
  popover-tip: 70,
  popover: 60,
  site-header: 50,
  site-footer: 50,
  loader-wrapper: 26,
  loader: 25
);

// set breakpoint for page layout shift based on sizes of site header components
$page-layout-breakpoint: map-get($site-header, logo-width-large) + map-get($site-header, search-container-width) + map-get($site-header, lateral-padding-large) * 3;

$page-body-max-width: 1375px;
$sidebar-width: 280px;

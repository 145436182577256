@import 'Stylesheets/abstracts/index.scss';

.container {
  display: flex;
  align-items: center;

  > * {
    &:not(:last-child) {
      margin-right: padding(x-small);
    }
  }
}

@import 'Stylesheets/abstracts/index.scss';

.loader {
  top: 50%;
  left: 50%;
  z-index: map-get($z-index, loader);
  display: block;
  width: 150px;
  height: 150px;
  border: 3px solid transparent;
  border-top-color: #009999;
  border-radius: 50%;
  animation: spin 1.8s ease-in-out infinite;

  &::after, &::before {
    position: absolute;
    content: '';
    border-radius: 50%;
  }

  &::before {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border: 3px solid transparent;
    border-top-color: #005f87;
    animation: spin 2.5s ease-in infinite;
  }

  &::after {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 3px solid transparent;
    border-top-color: #eb780a;
    animation: spin 1.25s ease-in-out infinite;
  }
}

.small {
  width: 75px;
  height: 75px;
  border-width: 2px;

  &::before, &::after {
    border-width: 2px;
  }
}

.extraSmall {
  width: 45px;
  height: 45px;
  border-width: 1px;

  &::before, &::after {
    border-width: 1px;
  }

  &::after {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* these declarations are for using this as a full page loader

/* // if using full page loader once loaded, you can load the backdrop, adjust opacity etc. */
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($z-index, loader-wrapper);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  transition: opacity 1s 1s ease-out;
}

.nested {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

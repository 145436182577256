.Popover {
  border-radius: $card-border-radius;
  box-shadow: map-get($shadow, filter);
  width: 450px;
  transition: opacity $duration $timing;
  max-width: 100vw;

  &.select {
    width: 338px;

    .Popover-tip {
      fill: palette(info-light-blue);
    }
  }
}

.Popover-tip {
  position: absolute;
  top: -10px;
  fill: palette(white);
}

// Component Layout
// -------------------

// Layout-only mixins and utility classes.

// They may contain:
// Nested classes/elements that have structural parameters only

// Utility Spacing Classes
// --------------------------------------------------------

// Create a a padding class in each direction for each value in $padding-sizes map
@each $name, $value in $padding-sizes {
  .l-pad-top-#{$name} {
    padding-top: $value;
  }

  .l-pad-right-#{$name} {
    padding-right: $value;
  }

  .l-pad-bottom-#{$name} {
    padding-bottom: $value;
  }

  .l-pad-left-#{$name} {
    padding-left: $value;
  }
}

// Utility Layout Classes
// --------------------------------------------------------


// Containers
// --------------------------------------------------------

@mixin proto-container($max-width: container-width(wide), $wide-padding: padding(large), $narrow-padding: padding(x-small)) {
  @include fluid-scale(max-width, $max-width + 2 * $wide-padding, $max-width + 2 * $narrow-padding);
  @include fluid-scale(padding-right padding-left, $wide-padding, $narrow-padding);
  margin-right: auto;
  margin-left: auto;
}

// Container
//
// The highest-level container for page content. Sets a max-width and centers its children.
@mixin container-max {
  width: 100%;
  max-width: container-width(max);
  margin-right: auto;
  margin-left: auto;
}

.l-container-max {
  @include container-max;
}

// Wide Container
@mixin container-wide {
  @include proto-container;
}

.l-container-wide {
  @include container-wide;
}

@mixin container-primary {
  @include fluid-scale(padding, padding(large), padding(small));
  max-width: container-width(primary);
  margin-right: auto;
  margin-left: auto;
}

.l-container-primary {
  @include container-primary;
}

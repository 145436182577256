@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';

.container {
  display: grid;
  grid-template:
    'icon user' auto
    'icon log-out' auto / 32px 1fr;
  column-gap: map-get($navigation, gutter);
  row-gap: 4px;
  align-items: center;
  background-color: palette(primary-dark-2);
}

.default {
  padding: 10px padding(small) 10px map-get($navigation, left-padding);
}

.tall {
  padding: 22px padding(small) 22px map-get($navigation, left-padding);
}

.user {
  grid-area: user;
}

.logOut {
  @include heading-primary;
  grid-area: log-out;
  color: palette(primary-bright-1);
}

.icon {
  grid-area: icon;
  font-size: 15px;
}

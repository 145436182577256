@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';

.wrapper {
  position: relative;
  margin-top: 10px;
  user-select: none;
}

.toggle-button {
  &::after {
    border-top-color: palette(white);
  }
}

.popover {
  user-select: none;
  background-color: palette(white);
  border-radius: $card-border-radius;

  @include respond(break(45)) {
    display: flex;
    flex-direction: column;
  }
}

.withSidebar {
  display: flex;
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_appearance.scss';

.wrapper {
  @include rounded-corners('bottom');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 200px;
  padding: padding(medium-large);
  text-align: center;
  background-color: palette(info-light-blue);
}

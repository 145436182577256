@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_layout.scss';

$narrow-breakpoint: break(40);

.container {
  @include fluid-scale(padding-right padding-left, site-header(lateral-padding-large), site-header(lateral-padding-small), $page-layout-breakpoint, $narrow-breakpoint);
  position: sticky;
  top: 0px;
  z-index: map-get($z-index, site-header);
  grid-area: header;
  padding: padding(medium-large) padding(x-large);
  background-color: palette(secondary-dark);

  @include respond($page-layout-breakpoint) {
    padding-top: site-header(vertical-padding-small);
    padding-bottom: site-header(vertical-padding-small);
  }
}

.inner {
  display: grid;
  grid-template:
    'logo-container . search-container' / auto 1fr site-header(search-container-width) + site-header(lateral-padding-large);
  align-items: center;
  max-width: $sidebar-width + $page-body-max-width;

  @include respond($page-layout-breakpoint) {
    grid-template:
      'nav-button . logo-container . filter-button' / auto 1fr auto 1fr auto;
  }
}

.logoContainer {
  grid-area: logo-container;
}

.searchContainer {
  grid-area: search-container;
  margin-left: padding(medium-large);

  @include respond($page-layout-breakpoint) {
    display: none;
  }
}

.navButtonContainer,
.filterButtonContainer {
  display: none;

  @include respond($page-layout-breakpoint) {
    display: block;
  }
}

.navButtonContainer {
  grid-area: nav-button;
}

.filterButtonContainer {
  grid-area: filter-button;
}

.barChartWrapper {
  max-width: 480px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 15px;

  svg {
    overflow: visible;
  }
}

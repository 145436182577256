@import 'Stylesheets/abstracts/index.scss';

.logo {
  @include respond(break(40)) {
    width: 121px;
  }
}

.fill1 {
  fill: palette(primary-bright-1);
}

.fill2 {
  fill: palette(white);
  transition: fill 0.2s;

  [href]:hover & {
    fill: palette(primary-bright-1);
  }
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

.list {
  @include rounded-corners(left);
  display: inline-block;
  flex-shrink: 0;
  width: 134px;
  padding-top: 18px;
  padding-bottom: 28px;
  vertical-align: top;
  background-color: palette(info-light-blue);

  @include respond(break(45)) {
    @include rounded-corners(bottom);
    display: flex;
    flex-wrap: wrap;
    align-items: top;
    order: 2;
    width: 100%;
    border-top-left-radius: 0;
  }
}

.group {
  margin: 17px 0 padding(x-x-small);
  list-style-type: none;

  + .group {
    margin-top: 20px;

    @include respond(break(45)) {
      margin-top: 0;
    }
  }

  @include respond(break(45)) {
    display: inline-block;
    margin: 0 0 padding(x-small);
  }
}

.item {
  @include copy-primary
    color: palette(neutral-70);
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-left: padding(medium-large);
  cursor: pointer;
  transition: background-color $duration $timing;

  @include respond(break(45)) {
    width: 132px;
    padding-right: padding(medium-large);
  }

  &:hover {
    background-color: palette(white);
  }

  &.active {
    @include copy-bold;
    color: palette(primary-mid-2);
  }
}

@import 'Stylesheets/abstracts/index.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px;
  background-color: palette(primary-dark-3);
}

.wrapperMobile {
  @include respond($page-layout-breakpoint + 1, min) {
    display: none;
  }
}

.wrapperDesktop {
  @include respond($page-layout-breakpoint) {
    display: none;
  }
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_appearance.scss';
@import 'Stylesheets/utility/_typography.scss';

$icon-size: map-get($navigation, icon-size);
$vertical-padding: map-get($navigation, vertical-padding);

.item {
  display: block;
}

.inner {
  display: flex;
  transition: color 0.2s, background-color 0.2s;

  &:hover,
  &.focus-visible {
    color: palette(white);
    background-color: palette(primary-dark-2);
  }
}

.active {
  color: palette(white);
  background-color: palette(primary-dark-2);
}

.link {
  display: grid;
  flex-grow: 1;
  grid-template: 'icon label' auto / $icon-size 1fr;
  grid-gap: map-get($navigation, gutter);
  align-items: center;
  min-height: $icon-size + $vertical-padding * 2;
  padding: $vertical-padding padding(small) $vertical-padding map-get($navigation, left-padding);
}

.label {
  grid-area: label;
}

.icon {
  grid-area: icon;
  margin-right: 12px;
}

.nestedList {
  @include list-unstyled;
  display: flex;
  flex-direction: column;
  color: rgba(palette(white), 0.6);
}

.nestedListToggle {
  padding-right: padding(small);
  padding-left: padding(small);
}

.toggleIcon {
  transition: transform 0.5s ease;

  &.isRotated {
    transform: rotate(-90deg);
  }
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

.button {
  @include hover-recolor(palette(primary-bright-1));
  color: palette(white);
}

.pillTheme {
  @include copy-primary;
  @include rounded-corners('all', $filter-border-radius);
  padding: 14px 24px;
}

.text {
  + .icon {
    margin-left: 10px;
  }
}

.iconHighlighted {
  color: palette(primary-bright-1);
}

@import 'Stylesheets/abstracts/index.scss';

.container {
  display: flex;
  flex-direction: column;
  grid-area: sidebar;
  justify-content: space-between;
  height: 100%;
  padding-top: padding(large);
  padding-bottom: padding(bottom);
  background-color: palette(primary-dark-1);

  @include respond($page-layout-breakpoint) {
    display: none;
  }
}

.navWrapper {
  flex-grow: 1;
  padding-bottom: padding(bottom);
}

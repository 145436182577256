// State
// --------------------

// Mixins and classes for setting styles based on component state.
// Classes are prepended with `is-` or `has-`.

.has-scroll-lock {
  position: absolute;
  width: 100%;
  overflow: hidden;
}

// Global z-index stack
// -----------------------------------

.DayPicker-Day--isFromDay::before {
    z-index: 0;
}

.DayPicker-Day--isFromDay span,
.DayPicker-Day--isToDay span {
  z-index: 1;
}

.Popover-tip {
  z-index: map-get($z-index, popover-tip);
}

.Popover {
  z-index: map-get($z-index, popover);
}

.m-filter-select-container {
  .m-filter-select {
    &__control {
      z-index: 5;
    }

    &__menu {
      z-index: 3;

      @include respond(break(100)) {
        z-index: 10;
      }
    }
  }
}

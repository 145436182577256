@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

$el-padding-vertical: 8px;
$el-padding-lateral: 18px;

@mixin rounded-element($radius: 6px, $bg-color: palette(info-light-gray), $border-color: $bg-color) {
  @include rounded-corners('all', $radius);
  padding: $el-padding-vertical $el-padding-lateral;
  color: palette(primary-mid-2);
  background-color: $bg-color;
  border: 1px solid $border-color;
}

@mixin rounded-button($radius: 6px, $bg-color: palette(info-light-gray)) {
  @include rounded-element($radius, $bg-color);
  @include focus-default;
  transition: color 0.2s, background-color 0.2s;

  &:disabled {
    color: rgba(palette(primary-mid-2), 0.3);
  }

  &:hover:not(:disabled) {
    color: palette(white);
    background-color: palette(primary-mid-2);
  }
}

.wrapper {
  @include fluid-scale(padding-right padding-left, padding(medium-large), padding(small));
  display: grid;
  grid-template:
    '. center right' auto / 1fr auto 1fr;
  grid-gap: padding(small);
  padding-top: padding(small);
  padding-bottom: padding(small);

  @include respond(break(75)) {
    grid-template:
      'center right' auto / auto 1fr;
  }

  @include respond(break(65)) {
    grid-template:
      'center' auto
      'right' auto / auto;
  }
}

.centerBlock {
  grid-area: center;

  @include respond(break(65)) {
    justify-self: center;
  }
}

.rightBlock {
  grid-area: right;
  justify-self: flex-end;

  @include respond(break(65)) {
    justify-self: center;
  }
}

.paginationList {
  @include list-unstyled;
  display: flex;
  align-items: baseline;
}

.paginationItem {
  display: flex;
  align-items: baseline;

  & + & {
    margin-left: padding(small);

    @include respond(break(50)) {
      margin-left: padding(x-x-small);
    }
  }
}

.inlineEl {
  & + & {
    margin-left: 0.5ch;
  }
}

.buttonDefault {
  @include rounded-button;
}

.buttonPill {
  @include rounded-button(17px, palette(neutral-25));
  position: relative;
  display: inline-block;
  padding: 0;
}

.input {
  background-color: transparent;
  outline: 0;
  appearance: none;
}

.inputText {
  @include copy-primary;
  @include rounded-element(6px, transparent, palette(neutral-55));
}

.inputSelect {
  padding: $el-padding-vertical 67px $el-padding-vertical $el-padding-lateral;
  border: none;
}

.selectIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  transform: translateY(-50%);
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_appearance.scss';

.container {
  align-self: start;
}

.inner {
  display: flex;
}

.half {
  grid-column: span 1;

  @include respond(map-get($card-size-breakpoints, wide)) {
    grid-column: span 2;
  }

  // switch back to two-up layout when sidebar disappears
  @include respond($page-layout-breakpoint) {
    grid-column: span 1;
  }

  @include respond(map-get($card-size-breakpoints, narrow)) {
    grid-column: span 2;
  }
}

.full {
  grid-column: span 2;
}

@import 'Stylesheets/abstracts/index.scss';

.container {
  display: grid;
  grid-template:
    'header header' auto
    'sidebar main' 1fr / #{$sidebar-width} 1fr;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  @include respond($page-layout-breakpoint) {
    display: flex;
    flex-direction: column;
  }
}

.m-nav-heading {
  @include heading-primary;
  padding-right: map-get($navigation, icon-size);
  padding-left: map-get($navigation, left-padding);
  color: palette(primary-bright-2);

  &--pad-bottom {
    margin-bottom: map-get($navigation, heading-padding);
  }
}

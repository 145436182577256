@import 'Stylesheets/abstracts/index.scss';

$header-height: 64px;
$icon-pos: 12px;

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-index, drawer);
  width: 100%;
  height: 100vh;
  background-color: palette(primary-dark-1);
}

.header {
  padding-top: padding(medium);
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: $header-height;
  overflow-x: hidden;
  overflow-y: auto;
}

.closeButtonWrapper {
  position: absolute;
  top: $icon-pos;
}

.right {
  right: $icon-pos;
}

.left {
  left: $icon-pos;
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';

.wrapper {
  @include fluid-scale(padding-right padding-left, padding(medium-large), padding(small));
  @include fluid-scale(padding-bottom, padding(medium-large), padding(small));
  display: flex;
  flex-wrap: wrap;
}

.figure {
  flex-grow: 1;
}

.figureDefault {
  flex-basis: 50%;
}

.figureLarge {
  flex-basis: 100%;
}

.number {}

.numberDefault {
  @include figure-text-large;
  line-height: 1;
}

.numberLarge {
  @include figure-text-x-large;
  line-height: 1;
}

.desc {
  @include copy-primary;
}

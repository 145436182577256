@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

$chart-layout-breakpoint: break(70);

.container {
  @include rounded-corners;
  display: flex;
  flex-direction: column;
  align-self: start;
  height: 100%;
  color: palette(neutral-55);
  background-color: palette(white);
  box-shadow: map-get($shadow, card);
}

.inner {
  @include fluid-scale(padding-top, padding(medium-large), padding(medium));
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.half {
  grid-column: span 1;

  @include respond(map-get($card-size-breakpoints, wide)) {
    grid-column: span 2;
  }

  // switch back to two-up layout when sidebar disappears
  @include respond($page-layout-breakpoint) {
    grid-column: span 1;
  }

  @include respond(map-get($card-size-breakpoints, narrow)) {
    grid-column: span 2;
  }
}

.full {
  grid-column: span 2;
}

.header {
  @include fluid-scale(padding-right padding-left, padding(medium-large), padding(small));
  @include fluid-scale(padding-bottom, padding(medium-large), padding(small));
  width: 100%;
}

.headerAbs {
  @include respond($chart-layout-breakpoint + 1, min) {
    position: absolute;
  }
}

.title {
  @include heading-secondary;
  color: palette(primary-mid-2);
}

.description {
  margin-top: 4px;
}

.body {
  flex-grow: 1;
  width: 100%;
  min-height: 200px;
}

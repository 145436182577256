@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';

.container {
  @include fluid-scale(margin-right margin-left, padding(medium-large), padding(small));
  @include fluid-scale(margin-bottom, padding(large), padding(medium-large));
  margin-top: 0px;
}

.columnsFull {
  @include respond(break(75) + 1, min) {
    columns: 2;
  }
}

.columnsHalf {
  @include respond(break(75) + 1, min) {
    columns: 2;
  }

  @include respond(map-get($card-size-breakpoints, narrow) + 1, min) {
    columns: 1;
  }

  @include respond(break(100) + 1, min) {
    columns: 2;
  }

  @include respond(map-get($card-size-breakpoints, wide) + 1, min) {
    columns: 1;
  }
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.item {
  display: table-row;
  vertical-align: baseline;
  border-bottom: 1px dashed #C4C4C4;

  & + & {
    margin-top: padding(x-small);
  }
}

.value {
  @include copy-x-large;
  padding-top: 0.607em;
  text-align: right;
}

.label {
  @include copy-primary;
  width: 100%;
  padding-top: 30px;
  padding-left: 1em;
}

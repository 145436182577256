.m-filter-select-container {
  @include rounded-corners(top);
  background-color: palette(info-light-blue);
  padding: padding(medium-large);

  // modifier classes at bottom of stylesheet for scoped overrides

  &:hover {
    .m-filter-select__control--is-focused {
      box-shadow: none;
      border: none;
    }
  }

  .m-filter-select {
    &__control {
      border-radius: $filter-border-radius;
      width: 278px;
      height: 24px;
      margin: 0 auto;
      border: none;

      @include respond(break(100)) {
        width: 316px;
        max-width: 100%;
        margin: 5px 0 0;
        background-color: palette(info-light-gray);
      }

      &--is-focused {
        box-shadow: none;
        border: none;
      }

      &--is-disabled {
        background-color: palette(neutral-35);
        color: palette(neutral-55);

        .m-filter-select__single-value, .m-filter-select__placeholder {
          color: palette(neutral-55);
        }
      }

      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='14' viewBox='0 0 14 14'%3E%3Cdefs%3E%3Cpath id='icon-search-a' d='M14.8907479,13.4765344 L17.5355339,16.1213203 L16.1213203,17.5355339 L13.4765344,14.8907479 C12.4957155,15.589209 11.2958304,16 10,16 C6.6862915,16 4,13.3137085 4,10 C4,6.6862915 6.6862915,4 10,4 C13.3137085,4 16,6.6862915 16,10 C16,11.2958304 15.589209,12.4957155 14.8907479,13.4765344 Z M10,14 C12.209139,14 14,12.209139 14,10 C14,7.790861 12.209139,6 10,6 C7.790861,6 6,7.790861 6,10 C6,12.209139 7.790861,14 10,14 Z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-4 -4)'%3E%3Cmask id='icon-search-b' fill='%23fff'%3E%3Cuse xlink:href='%23icon-search-a'/%3E%3C/mask%3E%3Cuse fill='%23000' fill-rule='nonzero' xlink:href='%23icon-search-a'/%3E%3Cg fill='%23777' mask='url(%23icon-search-b)'%3E%3Cpolygon points='0 22 22 22 22 0 0 0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        position: absolute;
        left: 13px;
        top: 12px;

        @include respond(break(100)) {
          display: none;
        }
      }
    }

    &__value-container {
      @include copy-primary;
      color: palette(neutral-55);
      padding-left: padding(large);

      @include respond(break(100)) {
        padding-left: padding(x-small);
      }
    }

    &__single-value {
      color: palette(primary-mid-2);
    }

    &__indicator-separator, &__dropdown-indicator {
      display: none;
    }

    &__dropdown-indicator {
      @include respond(break(100)) {
        display: inline-block;
        padding-left: 0;
      }
    }

    &__clear-indicator {
      @include respond(break(100)) {
        padding-right: 6px;
      }
    }

    &__icon {
      @include hover-recolor;
      color: palette(neutral-55);
      height: 20px;
      width: 20px;
    }

    &__menu {
      @include rounded-corners(bottom);
      @include copy-primary;
      background-color: palette(info-light-gray);
      width: 278px;
      height: auto;
      overflow: visible;
      box-shadow: map-get($shadow, filter);
      padding-top: padding(x-x-small);
      padding-bottom: padding(x-x-small);
      top: 48px;

      @include respond(break(100)) {
        background-color: (palette(white));
        width: 316px;
        max-width: 100%;
        top: 40px;
      }
    }

    &__menu-list {
      @include rounded-corners("all");
    }

    &__option {
      @include hover-recolor(palette(primary-mid-2));
      color: palette(neutral-55);
      background-color: palette(info-light-gray);
      transition: font-weight $duration $timing;
      padding: padding(x-x-small) padding(large) 0;

      @include respond(break(100)) {
        background-color: (palette(white));
      }

      &:hover {
        @include copy-bold;
        background-color: palette(info-light-gray);

        @include respond(break(100)) {
          background-color: palette(white);
        }
      }
    }
  }

  &--default {
    @include respond(break(100)) {
      padding: 0;
      background-color: palette(primary-dark-2);
    }
  }

  &--with-search {
    @include rounded-corners("left", $filter-border-radius);
    padding: 0px;
    background-color: palette(primary-dark-1);

    .m-filter-select {
      &__control {
        height: 54px;
        margin: 0px;
        background-color: palette(primary-dark-1);
        cursor: pointer;

        @include respond(break(100)) {
          width: 225px;
        }

        @include respond(break(60)) {
          width: 175px;
        }

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%2300BEFF' fill-rule='nonzero' d='M22.8647014,14.9945143 C22.8588241,10.6731947 19.3485476,7.17481812 15.0226017,7.17899937 C11.8491121,7.17900792 8.98845196,9.08960711 7.7757193,12.0191016 C6.56298664,14.9485961 7.23726982,18.3194406 9.48387429,20.5584291 C11.7304788,22.7974175 15.1064899,23.4631369 18.0362904,22.2448893 C20.9660909,21.0266417 22.872075,18.1646029 22.8647014,14.9945143 Z M18.4898554,23.3333507 C15.1191121,24.7349478 11.2350022,23.969036 8.65027779,21.3930738 C6.06555334,18.8171115 5.28978873,14.9389459 6.68504091,11.5685546 C8.08029308,8.19816336 11.3714902,6.00001388 15.022029,6.00000431 C19.9989681,5.99519353 24.0380407,10.0204789 24.0449559,14.9920594 C24.0533246,18.6391415 21.8605111,21.9317901 18.4898554,23.3333507 Z M20.5434032,21.3274447 L21.3822641,20.4980799 L25.5776743,24.5882665 C25.8066677,24.8115166 25.8113236,25.1781323 25.5880735,25.4071257 C25.5855954,25.4096675 25.583094,25.4121866 25.5805695,25.4146824 L25.5805695,25.4146824 C25.3476871,25.6449285 24.9733033,25.6462401 24.7388134,25.4176313 L20.5434032,21.3274447 Z'%3E%3C/path%3E%3C/svg%3E");
          width: 28px;
          height: 28px;
          background-repeat: no-repeat;
          position: absolute;
          left: 14px;
          top: 13px;

          @include respond(break(100)) {
            display: block;
          }
        }
      }

      &__value-container,
      &__placeholder {
        color: palette(white);
      }

      &__value-container {
        padding-left: 52px;
      }

      &__dropdown-indicator {
        display: inline-block;
        padding-right: 12px;
      }

      &__single-value {
        color: inherit;
      }

      &__icon {
        @include hover-recolor(palette(white));
        color: palette(primary-bright-1);
      }

      &__menu {
        @include rounded-corners("all");
      }
    }
  }
}

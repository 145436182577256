.m-chart {
  &__label {
    @include copy-x-x-small;
    text-transform: uppercase;
    letter-spacing: 0.027em;
  }
}

.apexcharts-gridlines-horizontal {
  .apexcharts-gridline:last-child {
    // hide grid line that overlaps with x-axis
    display: none;
  }
}

// Typography
// -----------------------------------

// Typography mixins and utility classes.

// These should be limited to `font` and other typographic properties only.

// Font/typographic helper mixins
// --------------------------------------------------------
@mixin font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Alignment
// --------------------------------------------------------
.t-align-center {
  text-align: center;
}

// Headings
// --------------------------------------------------------

@mixin proto-text($font-size: 14px) {
  font-weight: $medium;
  font-size: $font-size;
  line-height: 1.357;
}

@mixin proto-text-bold($font-size: 14px) {
  font-weight: $demi-bold;
  font-size: $font-size;
  line-height: 1.357;
}

@mixin proto-text-light($font-size) {
  font-weight: $regular;
  font-size: $font-size;
  line-height: 1.357;
}

@mixin heading-primary {
  @include proto-text-bold(11px);
  text-transform: uppercase;
  letter-spacing: 0.136em;
}

.t-heading-primary {
  @include heading-primary;
}

@mixin heading-secondary {
  @include proto-text(22px);
}

.t-heading-secondary {
  @include heading-secondary;
}

@mixin heading-tertiary {
  // @include fluid-scale(font-size, 28px, 22px);
  // @include fluid-scale(line-height, 1.143, 1.182);
}

.t-heading-tertiary {
  @include heading-tertiary;
}

@mixin heading-quaternary {
  // @include fluid-scale(font-size, 24px, 16px);
  // @include fluid-scale(line-height, 1.25, 1.375);
}

.t-heading-quaternary {
  @include heading-quaternary;
}

// Copy
// --------------------------------------------------------

@mixin copy-primary {
  @include proto-text;
}

.t-copy-primary {
  @include copy-primary;
}

@mixin copy-bold {
  @include proto-text-bold;
}

.t-copy-bold {
  @include copy-bold;
}

@mixin copy-x-x-small {
  @include proto-text(10.32px);
}

.t-copy-x-x-small {
  @include copy-x-x-small;
}

@mixin copy-x-small {
  @include proto-text(11px);
}

.t-copy-x-small {
  @include copy-x-small;
}

@mixin copy-x-small-bold {
  @include proto-text-bold(11px);
}

.t-copy-x-small-bold {
  @include copy-x-small-bold;
}

@mixin copy-small {
  @include proto-text(12px);
}

.t-copy-small {
  @include copy-small;
}

@mixin copy-medium-small {
  @include proto-text(13px)
}

.t-copy-medium-small {
  @include copy-medium-small;
}

@mixin copy-medium-large {
  @include proto-text(15px);
  line-height: 1.33;
}

.t-copy-medium-large {
  @include copy-medium-large;
}

@mixin copy-large {
  @include proto-text(20px);
}

.t-copy-large {
  @include copy-large;
}

@mixin copy-x-large {
  @include proto-text-light(28px);
}

.t-copy-x-large {
  @include copy-x-large;
}

@mixin copy-x-x-large {
  @include proto-text-light(34px);
}

.t-copy-x-x-large {
  @include copy-x-x-large;
}

@mixin figure-text-large {
  @include proto-text-light(50px);
}

@mixin figure-text-x-large {
  @include proto-text-light(80px);
}

.t-figure-text-x-large {
  @include copy-x-x-large;
}

// Links
// --------------------------------------------------------

@mixin link-underlined {
  text-decoration:  underline;
}

.t-link-underlined {
  @include link-underlined;
}

// Lists
// --------------------------------------------------------
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.t-list-unstyled {
  @include list-unstyled;
}

@mixin list-unordered-primary {
  padding: 0;
  margin: 0 0 0 1em;
}

.t-list-unordered-primary {
  @include list-unordered-primary;
}

@mixin list-ordered-primary {
  padding: 0;
  margin: 0 0 0 1em;
}

.t-list-ordered-primary {
  @include list-ordered-primary;
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_appearance.scss';

.filterBlock {
  & + & {
    margin-top: padding(small);
  }
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  padding: padding(x-small) padding(large);
}

.filterWrapperBackground {
  background-color: palette(primary-dark-2);
}

.applyButton {
  align-self: flex-start;
  background-color: palette(primary-dark-2);
}

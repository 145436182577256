//extra layer of nesting to overwrite react-form styles
.m-filter-wrapper-container {
  display: inline-block;

  @include respond(break(100)) {
    width: 100%;
  }

  &.m-filter-wrapper--with-search {
    width: auto;

    @include respond(break(75)) {
      .m-filter-wrapper__button-inner {
        width: auto;
      }
    }
  }

  &.m-filter-wrapper--Language {
    @include respond(break(100)) {
      width: auto;
    }
  }

  .m-filter-wrapper {
    &__button-outer {
      padding: 9px;
      display: flex;
      align-items: center;
      background-color: palette(primary-dark-1);
      border-radius: $filter-border-radius;
      border: none;
      cursor: pointer;
      box-shadow: none;

      &--Company {
        width: 194px;
      }

      &--Product {
        width: 206px;
      }

      &--card {
        padding: 13px 16px;
        box-shadow: map-get($shadow, card);
      }

      &--with-search {
        @include rounded-corners("left", $filter-border-radius);
      }

      &--mobile-dark {
        @include respond(break(100)) {
          width: 100%;
          background-color: palette(primary-dark-2);
          padding: 0;

          .m-filter-wrapper__button-inner {
            width: 100%;
            padding-left: 0;
          }
        }
      }
    }

    &__placeholder, &__single-value, &__button-inner {
      @include copy-primary;
      color: palette(white);
      padding: 0 10px;
    }

    &__button-inner {
      width: 225px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      text-align: left;
      user-select: none;
    }

    &__icon {
      @include hover-recolor(palette(white));
      color: palette(primary-bright-1);
      transition: transform $timing $duration;

      &--rotated {
        transform: rotateX(180deg) translateZ(-1px);
      }
    }

    &__dropdown-indicator {
      padding-left: 2px;
      margin-right: 8px;

      @include respond(break(100)) {
        margin-left: auto;
      }
    }

    &__clear-button {
      margin-right: 7px;
    }
  }
}

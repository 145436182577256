// Overrides to default react-table styles
.ReactTable {
  $border-color: palette(neutral-37);
  $cell-padding: 11px 13px;

  @mixin highlighted-cell($font-weight: $demi-bold) {
    color: palette(primary-mid-2);
    background-color: palette(info-light-gray);
    font-weight: $font-weight;
  }

  @include copy-primary;
  @include rounded-corners("bottom");
  border: none;

  .rt-th,
  .rt-td,
  .rt-thead .rt-th,
  .rt-thead .rt-td {
    padding: $cell-padding;
  }

  .rt-thead .rt-th,
  .rt-thead .rt-td,
  .rt-tbody .rt-tr-group,
  .rt-tbody .rt-td {
    border-color: $border-color;
  }

  .rt-thead.-header {
    @include highlighted-cell;
    border-bottom: 1px solid $border-color;
    box-shadow: none;
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 palette(primary-mid-1);
  }

  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 palette(primary-mid-1);
  }

  .rt-thead .rt-tr {
    text-align: left;
  }

  .rt-tbody {
    border-bottom: 1px solid $border-color;
  }

  .rt-tr {
    > :first-child {
      // match left padding to card header
      @include fluid-scale(padding-left, padding(medium-large), padding(small));
    }
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    @include highlighted-cell($medium);
  }
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

$padding: padding(medium-large) / 4;

.container {
  flex-grow: 1;
  min-height: 457px;
}

.inner {
  position: relative;
  display: flex;
  padding: 0 $padding * 2;
}

.heading {
  @include copy-bold;
  padding: $padding * 3 $padding * 3 $padding;
  color: palette(primary-mid-2);
}

.block {
  flex-basis: 50%;
  margin: 0 $padding;
}

.label {
  @include heading-primary;
  color: palette(neutral-55);
}

.inputWrapper {
  @include rounded-corners(all);
  padding: 5px;
  margin-top: 5px;
  color: palette(neutral-55);
  border: 1px solid rgba(palette(neutral-55), 0.5);
}

.input {
  @include copy-primary;
  width: 100%;
  appearance: none;
  color: currentColor;
  border: none;

  &:focus {
    outline-width: 0;
  }
}

.calendar {
  position: absolute;
  top: calc(100% - 10px);
  left: 50%;
  width: 316px;
  transform: translateX(-50%);
}

$range-border-radius: 50%;

.DayPicker {
  vertical-align: top;
  display: inline-block;
  justify-content: center;
  position: relative;
  outline: none;
  max-width: 315px;
  padding: 27px 17px;
}

.DayPicker-Caption {
  @include proto-text-bold(16px);
  text-align: center;
  color: palette(primary-mid-2);
  padding: 5px 0 padding(x-x-small);
  cursor: default;
}

.DayPicker-Weekday, .DayPicker-Day {
  @include copy-medium-small;
  display: inline-block;
  padding: 12px padding(x-x-small) padding(x-x-small);
  text-align: center;
  width: padding(large);
}

.DayPicker-Weekday {
  color: palette(primary-mid-2);

  abbr {
    border: 0;
    text-decoration: none;
  }
}

.DayPicker-Day {
  color: palette(neutral-55);
  height: padding(large);
  cursor: pointer;
  vertical-align: middle;
  outline: none;

  &--today {
    font-weight: 700;
  }

  &--outside {
    cursor: default;
    pointer-events: none;
    color: transparent;

    &::before {
      display: none;
    }
  }

  &--disabled {
    cursor: default;
  }

  &--isFromDay {
    position: relative;

    span {
      position: relative;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: palette(primary-dark-1);
      border-radius: $range-border-radius;
    }
  }

  &--isLeftEdge, &--selected:first-child {
    border-top-left-radius: $range-border-radius;
    border-bottom-left-radius: $range-border-radius;
  }

  &--isRightEdge, &--selected:last-child {
    border-top-right-radius: $range-border-radius;
    border-bottom-right-radius: $range-border-radius;
  }

  &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
    color: palette(white);
    font-weight: $demi-bold;
    background-color: palette(primary-mid-2);
    transition: background-color 0.8s;
  }

  &--selected:not(.DayPicker-Day--isLeftEdge):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    transition: none;
  }
}

.DayPicker-NavButton {
  position: absolute;
  width: 50%;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;

  &:after {
    display: block;
    position: absolute;
    top: 7.5px;
  }

  &--next {
    right: 0;
  }

  &--prev::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10' height='18' viewBox='0 0 10 18'%3E%3Cdefs%3E%3Cpolygon id='arrow-a' points='19.72 8.273 13 14.74 6.278 8.273 6.277 8.273 4.727 9.766 13 17.727 21.273 9.766 19.723 8.273'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='rotate(90 10.915 7.085)'%3E%3Cmask id='arrow-b' fill='%23fff'%3E%3Cuse xlink:href='%23arrow-a'/%3E%3C/mask%3E%3Cg fill='%233769AC' mask='url(%23arrow-b)'%3E%3Cpolygon points='0 26 26 26 26 0 0 0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    left: 13px;
    background-repeat: no-repeat;
  }

  &--next::after {
    right: 20px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10' height='18' viewBox='0 0 10 18'%3E%3Cdefs%3E%3Cpolygon id='arrow-next-a' points='19.72 8.273 13 14.74 6.278 8.273 6.277 8.273 4.727 9.766 13 17.727 21.273 9.766 19.723 8.273'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='rotate(-90 7.085 15.085)'%3E%3Cmask id='arrow-next-b' fill='%23fff'%3E%3Cuse xlink:href='%23arrow-next-a'/%3E%3C/mask%3E%3Cg fill='%233769AC' mask='url(%23arrow-next-b)'%3E%3Cpolygon points='0 26 26 26 26 0 0 0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
  }
}

@import 'Stylesheets/abstracts/index.scss';

.container {
  grid-area: main;
  padding: padding(large);
}

.inner {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: padding(large);
  max-width: $page-body-max-width;
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

$clear-button-padding: 22px;

.searchContainer {
  @include rounded-corners('right', $filter-border-radius);
  display: flex;
  flex-grow: 1;
  color: palette(primary-mid-2);
  background-color: palette(white);
  box-shadow: map-get($shadow, card);
}

.searchInput {
  @include copy-large;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding-left: padding(small);
  color: inherit;
  background-color: transparent;
  border: none;
  appearance: none;

  &:focus {
    outline-width: 0px;
  }
}

.clearButton {
  @include heading-primary;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 $clear-button-padding;
  line-height: 1;
  color: palette(primary-mid-2);
}

.clearText {}

.clearIcon {
  margin-left: 8px;
}

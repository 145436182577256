// Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc.

// These should be limited to appearance-related properties only.

// Visibility
// --------------------------------------------------------
.a-pos-rel {
  position: relative;
}

.a-pos-abs {
  position: absolute;
}

.a-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.a-hide-overflow {
  overflow: hidden;
}

@mixin focus-default {
  &:focus:not(:focus-visible) {
    outline-width: 0;
  }

  &:focus-visible {
    outline: auto 4px;
  }
}

@mixin hover-recolor($hover-color: palette(primary-dark-1)) {
  transition: color 0.2s;

  &:hover {
    color: $hover-color;
  }
}

// Color
// --------------------------------------------------------
// Create a `.a-color-` class for each color in $palette map
@each $name, $value in $palette {
  .a-color-#{$name} {
    color: $value;
  }
}

// Create a `.a-bg-` class for each color in $palette map
@each $name, $value in $palette {
  .a-bg-#{$name} {
    background-color: $value;
  }
}

// Icons
// --------------------------------------------------------
@mixin proto-icon {
  display: inline;
  vertical-align: middle;
}

.a-icon {
  @include proto-icon;
}

// Links, Buttons
// --------------------------------------------------------
@mixin button-unstyled {
  appearance: none;
  padding: 0;
  background: transparent;
  border: 0;
}

// Transitions
// --------------------------------------------------------


// Misc
// --------------------------------------------------------
@mixin rounded-corners($sides: "all", $radius: $card-border-radius) {
  @if $sides == "all" {
    border-radius: $radius;
  } @else if $sides == "top" {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  } @else if $sides == "bottom" {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  } @else if $sides == "right" {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  } @else if $sides == "left" {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  } @else {
    @error '"#{$sides}" is not a possible argument. Avaialable arguments: "all", "top", "bottom".';
  }
}

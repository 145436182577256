@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_appearance.scss';

.container {
  @include rounded-corners('bottom');

  &.light {
    color: palette(primary-mid-2);
    background-color: palette(info-light-gray);
  }

  &.dark {
    color: palette(white);
    background-color: rgba(#000, 0.7);
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  padding-right: padding(x-small);
  padding-left: padding(x-small);
}

.left {
  display: flex;
  margin-left: -1 / 2 * padding(x-small);
}

.right {
  display: flex;
  margin-right: -1 / 2 * padding(x-small);
}

.button {
  @include hover-recolor;
  display: flex;
  align-items: center;
  padding: padding(x-small) padding(x-small) / 2;
  font-size: 11px;
  font-weight: $medium;
  line-height: 1;

  & + & {
    margin-left: 5px;
  }
}

.buttonIconArrow {
  transition: transform 0.5s ease;

  &.isRotated {
    transform: rotate(-180deg);
  }
}

.buttonIconLeft {
  order: -1;
  margin-right: 10px;
}

.buttonIconRight {
  margin-left: 10px;
}

.info {
  @include rounded-corners;
  padding: padding(medium) padding(medium-large);
  color: palette(neutral-60);
  background-color: palette(white);
}

.infoText {}

.popover {
  background-color: palette(white);
  border-radius: $card-border-radius;
}

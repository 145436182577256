@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

.container {
  padding: padding(medium-large);
}

.containerDefault {
  @include respond(break(100)) {
    padding: 0 0 5px;
  }
}

.label {
  @include heading-primary;
  color: palette(neutral-55);
}

.checkboxContainer {
  @include copy-primary;
  padding-top: padding(small);
  color: palette(neutral-55);

  @include respond(break(100)) {
    margin-left: padding(x-small);
    color: palette(white);
  }
}

.checkbox {
  position: absolute;
  opacity: 0;

  &:checked + .checkText::after {
    background-color: palette(primary-mid-2);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10'%3E%3Cpolyline fill='none' stroke='%23FFF' stroke-width='1.5' points='4 8.5 7.329 12 14 5' transform='translate(-3 -4)'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.checkText {
  position: relative;
  display: inline-block;
  padding-left: 27px;

  &::before, &::after {
    position: absolute;
    top: -1px;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    content: '';
    border-radius: 3px;
  }

  &::before {
    background-color: palette(neutral-37);
    transition: background-color $duration $timing;
  }

  &:hover::before {
    background-color: palette(neutral-20);
  }
}

.selected {
  @include copy-bold;
  display: inline-block;
  padding-top: 5px;
  color: palette(primary-mid-2);
}

.icon {
  @include hover-recolor;
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
  color: palette(neutral-55);
}

.button {
  margin-left: padding(x-x-small);
}

@import 'Stylesheets/abstracts/index.scss';
@import 'Stylesheets/utility/_typography.scss';
@import 'Stylesheets/utility/_appearance.scss';

.container {
  @include rounded-corners('top');
  background-color: palette(info-light-gray);
}

.inner {
  @include fluid-scale(padding-right padding-left, padding(medium-large), padding(small));
  display: flex;
  flex-direction: column-reverse;
  padding-top: padding(medium-large);
  padding-bottom: padding(small);
}

.heading {
  @include heading-secondary;
  margin-top: padding(medium);
  font-size: 26px;
  color: palette(primary-mid-2);
  text-transform: none;
  letter-spacing: normal;
}

.linkContainer {}

.link {
  @include heading-primary;
  @include rounded-corners('all', 14px);
  @include focus-default;
  display: inline-flex;
  align-items: center;
  padding: 6px 13px 6px 12px;
  color: palette(info-light-gray);
  background-color: palette(primary-mid-2);
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: palette(primary-dark-1);
  }
}

.linkIcon {
  transform: rotate(180deg);
}

.linkText {
  margin-left: 7px;
}
